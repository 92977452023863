import React from "react";
import classNames from "classnames";
import ViewerAvatar from "components/ViewerAvatar";
import { ReactComponent as CommentsEmpty } from "images/position/Comment.svg";
import UserAvatar from "components/UserAvatar";
import TextareaAutosize from "react-autosize-textarea";
import Timestamp from "react-timestamp";
import Button from "components/UI/Button";

import styles from "./Comment.module.scss";

export default ({
  newComments,
  viewer,
  handleCreateComment,
  handleRemoveComment,
  comment,
  setComment,
  sidebar,
  html,
}) => {
  // console.log({ html });
  return (
    <div className={classNames(styles.comments, sidebar && styles.sidebar)}>
      {newComments && newComments.length > 0 && (
        <div className={styles.padding}>
          <h5 className={styles.commentsTitle}>
            Comments • {newComments.length}
          </h5>
          <div className={styles.commentsBox}>
            {newComments.map((c) => {
              const date = new Date(c.created_at * 1000);
              return (
                <div
                  key={`comment-${Math.random(10)}`}
                  className={styles.comment}
                >
                  <div className={styles.left}>
                    {viewer && c.createdBy.name === viewer.name ? (
                      <ViewerAvatar size="small" />
                    ) : (
                      <UserAvatar member={c.createdBy} size="small" />
                    )}
                  </div>
                  <div className={styles.right}>
                    <div className={styles.upper}>
                      <div className={styles.keepLeft}>
                        <p className={styles.name}>{c.createdBy.name}</p>
                        <p className={styles.time}>
                          <Timestamp date={date} autoUpdate />
                        </p>
                      </div>
                      {c.createdBy.id === viewer.id && (
                        <div className={styles.remove}>
                          <Button
                            bStyle={["none"]}
                            type="button"
                            onClick={() => handleRemoveComment(c)}
                          >
                            <i className="far fa-times" />
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className={styles.commentText}>
                      {html ? (
                        <p
                          className={styles.commentMessage}
                          dangerouslySetInnerHTML={{ __html: c.comment }}
                        />
                      ) : (
                        <p className={styles.commentMessage}>{c.comment}</p>
                      )}{" "}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {sidebar && newComments && newComments.length < 1 && (
        <div className={classNames(styles.padding, styles.empty)}>
          <div className={styles.placeholder}>
            <CommentsEmpty />
            <h5>Be the first to comment</h5>
            <p>Share your thoughts with the team</p>
          </div>
        </div>
      )}
      <div className={styles.newComment}>
        <form onSubmit={handleCreateComment}>
          <div className={styles.left}>
            <div className={styles.avatar}>
              <ViewerAvatar size="small" />
            </div>
          </div>
          <div className={styles.right}>
            <TextareaAutosize
              maxRows={8}
              name="comment"
              placeholder="Add a comment"
              className={classNames(
                styles.textarea,
                comment.length > 0 && styles.active
              )}
              rows={1}
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              id="commentTextarea"
              data-gramm_editor="false"
            />
            {comment.length > 0 && (
              <div className={styles.buttons}>
                <Button bStyle={["primary", "small"]} type="submit">
                  Send
                </Button>
                <Button
                  bStyle={["newsimple", "nopadding"]}
                  type="button"
                  onClick={() => setComment("")}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
