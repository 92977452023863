import React, { useState, useEffect } from "react";

import Comment from "./Comment";
import EditorWrapper from "./EditorWrapper";
import serializer from "components/TextEditor/editorSerializer";
import styles from "./Comments.module.scss";

export default ({
  setComment,
  editorValue,
  setEditorValue,
  handleCreateComment,
  handleUpdateComment,
  handleRemoveComment,
  editNote,
  setEditNote,
  comments,
  viewer,
}) => {
  const userComment = comments.find(
    (comment) => comment.createdBy.id === viewer.id
  );
  const handleSave = () => {
    const comment = serializer.serialize(editorValue);

    if (userComment) {
      handleUpdateComment({
        id: userComment.id,
        update: comment,
      });
    } else {
      handleCreateComment({
        comment,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <EditorWrapper
        defaultValue={editorValue}
        setEditorValue={setEditorValue}
        handleSave={
          !userComment ||
          userComment.comment !== serializer.serialize(editorValue)
            ? handleSave
            : null
        }
      />

      <div className={styles.notes}>
        {[...comments].reverse().map((comment) => {
          if (comment.createdBy.id === viewer.id) {
            return null;
          }
          return (
            <Comment
              key={"Comment-" + comment.id}
              note={comment}
              viewer={viewer}
              editNote={editNote}
              setEditNote={setEditNote}
              handleUpdateCandidateNote={handleUpdateComment}
              handleDeleteCandidateNote={handleRemoveComment}
            />
          );
        })}
      </div>
    </div>
  );
};
