import React, { useState } from "react";
import UserAvatar from "components/UserAvatar";
import Button from "components/UI/Button";
import Icon from "components/UI/Icon";
import styles from "./Comments.module.scss";

export default ({
  note,
  editNote,
  setEditNote,
  handleUpdateCandidateNote,
  handleDeleteCandidateNote,
  viewer,
}) => {
  const [comment, setComment] = useState(note.comment);

  return (
    <div className={styles.note}>
      <div className={styles.left}>
        <div className={styles.avatar}>
          {note.createdBy && (
            <UserAvatar member={note.createdBy} size="small" />
          )}
        </div>
        <div className={styles.comment}>
          <p dangerouslySetInnerHTML={{ __html: note.comment }}></p>
        </div>
      </div>
    </div>
  );
};
