import React from "react";
import {
  compose,
  withState,
  withHandlers,
  branch,
  renderComponent,
} from "recompose";
import { graphql } from "react-apollo";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import { withRouter } from "react-router-dom";
import withStore from "utils/withStore";
import { RESET_ORG } from "store/actionTypes";
import FullPage from "components/Loaders/FullPage";
import { getAccountCookie } from "client/authRoutes";
import axios from "utils/axios";

const SWITCH_ACCOUNT = loader("client/Mutations/switchAccount.graphql");
const INVITES_QUERY = loader("client/Queries/Invites7.graphql");

export default compose(
  withRouter,
  withState("accountModal", "setAccountModal", false),
  withStore("inviteStore"),
  withState(
    "open",
    "setOpen",
    window.innerWidth < 960 && !document.body.classList.contains("forceDesktop")
      ? false
      : true
  ),
  graphql(INVITES_QUERY),
  graphql(SWITCH_ACCOUNT, {
    name: "switchAccount",
  }),
  withHandlers({
    handleSwitchAccount:
      ({ dispatch, switchAccount, history }) =>
      async (id) => {
        // console.log("switching to account: ", id, "typeof id: ", typeof id)

        await axios.post(
          getAccountCookie,
          { account_id: id },
          { withCredentials: true }
        );

        switchAccount({
          variables: {
            id,
          },
        }).then((res) => {
          dispatch({ type: RESET_ORG });

          window.localStorage.removeItem("recentOrg");
          history.push("/dashboard");
        });
      },
  }),
  handleLoading(() => <FullPage />)
);
