import React from "react";
import { withRouter } from "react-router-dom";
import { compose, withHandlers, withState } from "recompose";
import { graphql } from "react-apollo";
import FullPage from "components/Loaders/FullPage";
import { loader } from "graphql.macro";
import handleLoading from "utils/loader";
import createSteps from "../createSteps";
import deserialize from "utils/deserialize";

const CANDIDATE_QUERY = loader("client/Queries/CandidateQuery198.graphql");
const SAVE_EVALUATION = loader("./saveEvaluation5.graphql");
const UPSERT_LEADERSHIP_SPAN_EVALUATION = loader(
  "./upsertLeadershipSpanEvaluation1.graphql"
);
const CREATE_COMMENT = loader("./createComment3.graphql");
const UPDATE_COMMENT = loader("./updateComment1.graphql");
const REMOVE_COMMENT = loader("./removeComment1.graphql");

export default compose(
  withRouter,
  withState("comment", "setComment", ({ dim: { comments }, viewer }) => {
    const userComment = comments.find(
      (comment) => comment.createdBy.id === viewer.id
    );

    const comment = userComment ? userComment.comment : "";
    return comment;
  }),
  withState("editNote", "setEditNote", false),
  graphql(SAVE_EVALUATION, {
    name: "saveEvaluation",
    options: ({
      viewer,
      setSteps,
      assessmentRows,
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      update: (proxy, { data: { evaluateCandidate } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        data.candidateInTeam.evaluation = [
          ...data.candidateInTeam.evaluation.filter(
            (e) => !(e.item_id === evaluateCandidate.item_id)
          ),
          evaluateCandidate,
        ];

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setSteps(createSteps(data.candidateInTeam, viewer, assessmentRows));
      },
    }),
  }),
  graphql(UPSERT_LEADERSHIP_SPAN_EVALUATION, {
    name: "upsertLeadershipSpanEvaluation",
    options: ({
      viewer,
      setSteps,
      assessmentRows,
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
    }) => ({
      update: (proxy, { data: { upsertLeadershipSpanEvaluation } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setSteps(createSteps(data.candidateInTeam, viewer, assessmentRows));
      },
    }),
  }),
  graphql(CREATE_COMMENT, {
    name: "createComment",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
      setSteps,
      assessmentRows,
      viewer,
    }) => ({
      update: (proxy, { data: { createComment } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        if (data.candidateInTeam.comments) {
          data.candidateInTeam.comments = [
            ...data.candidateInTeam.comments,
            createComment,
          ];
        } else {
          data.candidateInTeam.comments = [createComment];
        }

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setSteps(createSteps(data.candidateInTeam, viewer, assessmentRows));
      },
    }),
  }),
  graphql(UPDATE_COMMENT, {
    name: "updateComment",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
      setSteps,
      assessmentRows,
      viewer,
    }) => ({
      update: (proxy, { data: { updateComment } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        const index = data.candidateInTeam.comments.findIndex(
          (c) => c.id === updateComment.id
        );

        data.candidateInTeam.comments[index] = updateComment;

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setSteps(createSteps(data.candidateInTeam, viewer, assessmentRows));
      },
    }),
  }),
  graphql(REMOVE_COMMENT, {
    name: "removeComment",
    options: ({
      match: {
        params: { candidateId, teamId, positionId, orgId, candidateType },
      },
      setSteps,
      assessmentRows,
      viewer,
    }) => ({
      update: (proxy, { data: { removeComment } }) => {
        const data = proxy.readQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
        });

        let evalKey;

        data.candidateInTeam.comments = [
          ...data.candidateInTeam.comments.filter(
            (c) => c.id !== removeComment.id
          ),
        ];

        proxy.writeQuery({
          query: CANDIDATE_QUERY,
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            team_id: parseInt(teamId, 10),
            position_id: parseInt(positionId, 10),
            org_id: parseInt(orgId, 10),
          },
          data,
        });

        setSteps(createSteps(data.candidateInTeam, viewer, assessmentRows));
      },
    }),
  }),
  handleLoading(() => <FullPage />),
  withHandlers({
    handleSaveRating:
      ({
        saveEvaluation,
        match: {
          params: { candidateId, positionId, candidateType },
        },
      }) =>
      async ({ evaluation_type, item_id, evaluation }) => {
        await saveEvaluation({
          variables: {
            cand_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,

            pos_id: parseInt(positionId, 10),
            evaluation_type,
            item_id,
            evaluation,
          },
        });
      },
    handleSaveSpanRating:
      ({
        upsertLeadershipSpanEvaluation,
        match: {
          params: { candidateId, positionId, candidateType },
        },
      }) =>
      async (span_id, evaluation) => {
        await upsertLeadershipSpanEvaluation({
          variables: {
            user_id:
              candidateType === "candidate" ? parseInt(candidateId, 10) : null,
            person_id:
              candidateType === "person" ? parseInt(candidateId, 10) : null,
            position_id: parseInt(positionId, 10),
            span_id,
            evaluation,
          },
        });
      },
    handleCreateComment:
      ({ comment, createComment, setComment, match, dim }) =>
      async (e) => {
        // e.preventDefault();

        createComment({
          variables: {
            obj_type: "position",
            obj_id: parseInt(match.params.positionId, 10),
            subject_type: "candidate",
            subject_id: parseInt(match.params.candidateId, 10),
            item_type: "assessment_row",
            item_id: dim.id,
            comment,
          },
        });
      },
    handleUpdateComment:
      ({ updateComment }) =>
      async ({ id, update }) => {
        updateComment({
          variables: {
            id,
            update,
          },
        });
      },
    handleRemoveComment:
      ({ removeComment }) =>
      async (id) => {
        await removeComment({
          variables: {
            id: id,
          },
        });
      },
  })
);
