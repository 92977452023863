import React from "react";
import ReactMarkdown from "react-markdown";
import ViewerAvatar from "components/ViewerAvatar";
import MessageLoading from "components/UI/MessageLoading";
import styles from "./ChatInterface.module.scss";
import classNames from "classnames";
import Icon from "components/UI/Icon";

export default ({ role, isLoading, content, messageActions }) => {
  if (role === "assistant") {
    return (
      <div className={classNames("message", styles.messageWrap)}>
        <div className={styles.message}>
          {isLoading && content.length < 1 ? (
            <MessageLoading />
          ) : (
            <ReactMarkdown>{content}</ReactMarkdown>
          )}

          {messageActions && !isLoading && (
            <div className={styles.messageActions}>
              {messageActions.map(({ action, display }, index) => {
                return (
                  <div
                    key={`message-action-${index}`}
                    onClick={() => action(content)}
                  >
                    {display}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }

  if (role === "user") {
    return (
      <div className={classNames("message", styles.messageWrap, styles.user)}>
        <div className={styles.message}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
      </div>
    );
  }
};
