import React, { useState, useEffect } from "react";
import TextEditor from "components/TextEditor";
import serializer from "components/TextEditor/editorSerializer";

export default (props) => {
  const [rerender, forceUpdate] = useState(false);
  const { defaultValue, handleSave, setEditorValue } = props;

  useEffect(() => {
    if (defaultValue) {
      forceUpdate(true);
    }
  }, [defaultValue]);

  return (
    <TextEditor
      forceUpdate={forceUpdate}
      rerender={rerender}
      value={defaultValue}
      handleChange={(edit) => {
        setEditorValue(edit);
      }}
      handleSave={handleSave}
    />
  );
};
