import React from "react";
import Popup from "reactjs-popup";
import classNames from "classnames";
import Modal from "react-modal";
import Icon from "components/UI/Icon";
import Button from "components/UI/Button";
import Comments from "./Comments";

import compose from "./compose";

import styles from "./Comment.module.scss";

export default compose(
  ({
    data: { viewer },
    comments,
    handleCreateComment,
    handleRemoveComment,
    comment,
    setComment,
    objType,
    objId,
    subjectType,
    subjectId,
    itemType,
    itemId,
    relative,
    showAll,
    modal,
    openModal,
    setOpenModal,
    noIcon,
    html,
  }) => {
    const newComments = showAll
      ? comments
      : comments.filter((c1) => c1.item_type === itemType);

    return (
      <div
        className={classNames(
          styles.commentWrapper,
          relative && styles.relative
        )}
      >
        {modal ? (
          <div className={styles.buttonWrapper}>
            {noIcon ? (
              <Button bStyle={["none"]} onClick={() => setOpenModal(true)}>
                {newComments.length === 0 ? (
                  <>
                    <Icon icon="Plus" marginRight />
                    Add comment
                  </>
                ) : (
                  <>{"View comments (" + newComments.length + ")"}</>
                )}
              </Button>
            ) : (
              <Button
                bStyle={["none"]}
                type="button"
                onClick={() => setOpenModal(true)}
              >
                <div className={styles.icon}>
                  <Icon icon="Chat" />
                </div>
                {newComments.length > 0 && (
                  <p className={styles.number}>{newComments.length}</p>
                )}
              </Button>
            )}
          </div>
        ) : (
          <div className="commentsPopupWrapper">
            <Popup
              trigger={
                <div className={styles.buttonWrapper}>
                  <Button bStyle={["none"]} type="button">
                    <div className={styles.icon}>
                      <Icon icon="Chat" />
                    </div>
                    {newComments.length > 0 && (
                      <p className={styles.number}>{newComments.length}</p>
                    )}
                  </Button>
                </div>
              }
              className={styles.popup}
              on="click"
              keepTooltipInside="body"
              position="bottom right"
              closeOnDocumentClick
              repositionOnResize
              arrow={false}
            >
              <div className={styles.popover}>
                <Comments
                  newComments={newComments}
                  viewer={viewer}
                  handleCreateComment={handleCreateComment}
                  handleRemoveComment={handleRemoveComment}
                  comment={comment}
                  setComment={setComment}
                  html={html}
                />
              </div>
            </Popup>
          </div>
        )}
        {modal && (
          <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            shouldCloseOnOverlayClick
            className="commentModal"
          >
            <Button
              bStyle="close small"
              type="button"
              onClick={() => setOpenModal(false)}
            >
              <i className="fal fa-times" />
            </Button>
            <Comments
              newComments={newComments}
              viewer={viewer}
              handleCreateComment={handleCreateComment}
              handleRemoveComment={handleRemoveComment}
              comment={comment}
              setComment={setComment}
              sidebar
              html={html}
            />
          </Modal>
        )}
      </div>
    );
  }
);
